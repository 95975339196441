import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// icon
import { aliases, mdi } from 'vuetify/iconsets/mdi'

// pinia
import { createPinia } from 'pinia'

// line liff
import liff from '@line/liff'

// vcalendar
import { setupCalendar, Calendar } from 'v-calendar'
import 'v-calendar/style.css'

const axios = require('axios')
const pinia = createPinia()

const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
})

liff.init({ 
  liffId: process.env.VUE_APP_LiffId,
  withLoginOnExternalBrowser: true,
}).then(async () => {  
  
  const response = await axios.get(
    process.env.VUE_APP_ipify_API,
  )
  let ip = response.data
  
  await axios.post(
    process.env.VUE_APP_CatcubeSalonApiUrl + process.env.VUE_APP_CatcubeSalonApiUrlRoute_LiffLogin,
    {
        group: 1, // 0:unknow, 1:shop user, 2:customer user
        userId: liff.getContext().userId,
        os: liff.getOS(),
        ip: ip,
        liffId: liff.getContext().liffId,
        liffVersion: liff.getVersion(),
        lineVersion: liff.getLineVersion(),
    },
    {
        headers: {
            authorizationToken: liff.getAccessToken()
        }
    }
  )

  const app = createApp(App)
  app.use(pinia)
  app.use(vuetify)
  app.use(router)
  app.use(setupCalendar, {})

  app.component('VCalendar', Calendar)

  app.mount('#app')

}).catch(function(error) {
  console.log("init LIFF error", error);
})


